//
// footer.scss
//

.footer {
  padding: 70px 0 25px;
  background: darken($dark, 5%);
  background-position: center center;
  background-size: cover;
  width: 100%;
  background-repeat: no-repeat;

  .footer-sub-menu {
    li {
      padding: 6px 0;

      .footer-link {
        color: rgba($white, 0.6);
        transition: all 0.5s;

        &:hover {
          color: $white;
          margin-left: 5px;
        }
      }
    }
  }
}