//
// components.scss
//

.btn {
  border-radius: 3px;
  padding: 12px 30px;
  font-size: 15px !important;
  transition: all 0.5s;

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    transform: translateY(-2px);
    transition: all 0.5s;
  }

  &.btn-lg {
    padding: 10px 22px !important;
    font-size: 18px !important;
  }

  &.btn-sm {
    padding: 8px 16px !important;
    font-size: 14px !important;
  }

  @each $color,
  $value in $theme-colors {
    &.btn-#{$color} {
      background: linear-gradient(to right, $value 0%, lighten($value, 10%) 100%);
      border: none;
      color: $white;
      box-shadow: 0 4px 20px rgba($value, .15);

      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        box-shadow: 0 3px 10px rgba($value, 0.5);
        color: $white !important;
      }
    }
    &.btn-outline-#{$color} {
      border: 1px solid #{$value} !important;
      color: #{$value} !important;

      &:hover,
      &:focus,
      &.focus,
      &:active,
      &.active {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        box-shadow: 0 3px 10px rgba($value, 0.5);
        color: $white !important;
      }
    }
  }

  &.btn-light {
    background-color: $light;
    border: 1px solid $light;
    color: $dark !important;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: lighten($light, 3%);
      border: 1px solid lighten($light, 3%);
      box-shadow: 0 0 0 2px rgba($light, .08);
      color: $dark !important;
    }
  }

  &.btn-outline-light {
    border: 1px solid $light;
    color: $light !important;

    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      background-color: $light;
      border: 1px solid $light;
      color: $dark !important;
    }
  }
}

// Badge
.badge {
  padding: 6px 16px;
  font-weight: 400;

  &.badge-primary {
    background: rgba($primary, 0.1);
    box-shadow: 0 1px 2px rgba($primary, 0.5);
    color: $primary;
  }
}
